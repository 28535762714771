@font-face {
  font-family: 'Recoleta';
  src: url('/fonts/Recoleta-Bold.woff2') format('woff2'),
      url('/fonts/Recoleta-Bold.woff') format('woff'),
      url('/fonts/Recoleta-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Knockout-92UltmtCruiserwt';
  src: url('/fonts/Knockout-92UltmtCruiserwt.otf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Hatton';
  src: url('/fonts/PP Hatton Ultralight 200.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Hatton';
  src: url('/fonts/PP Hatton Ultralight 500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Hatton';
  src: url('/fonts/PP Hatton Ultralight 700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MariaConnected';
  src: url('/fonts/Maria-Connected.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'Apris';
  src: url('/fonts/Apris-Regular.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'ArialNarrowBold';
  src: url('/fonts/ArialNarrowBold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/Rubik.ttf') format('truetype');
}